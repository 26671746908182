import * as React from "react";

import Layout from "../../components/Layout";
import BlogRoll from "../../components/BlogRoll";

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <div
          style={{
            backgroundImage: `url('/img/thegirls.jpg')`,
            display: "grid",
            alignItems: "center",
            gridColumn: "full",
            height: "500px",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
          className="jumbotron full-width-image-container"
        >
          <h1>
            Stories & Events
          </h1>
        </div>
        <section className="section">
          <BlogRoll />
        </section>
      </Layout>
    );
  }
}
